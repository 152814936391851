import { Button, ButtonTargetKind } from "@components/Button";
import { LandingFeed } from "@components/Home/LandingPage/LandingFeed";
import {
  LandingSeeRecentDonationsButton,
  LandingSeeRecentDonationsButtonsSection,
  defaultPageLayoutCss,
  defaultPageLayoutFooterCss,
  landingFeedPageSectionCss,
} from "@components/Home/LandingPage/styles";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { PivotalBanner } from "@components/PivotalBanner";
import { DefaultPageLayout } from "@components/layout/DefaultPageLayout";
import { PageSection, SECTION_WIDTH_VAR } from "@components/layout/PageSection";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { NextPage } from "next";

import { FeedItemResponse } from "@every.org/common/src/codecs/entities";
import { FeedItemType } from "@every.org/common/src/entity/types";
import { LoggedOutAATest } from "@every.org/common/src/helpers/abtesting/LoggedOutAATest";
import {
  getRoutePath,
  ClientRouteName,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

import { ReactComponent as UserImage } from "src/assets/illustrations/UserAvatarPlaceholder.svg";
import { ReactComponent as BottomLeftBgImage } from "src/assets/illustrations/new_landing_bg_1.svg";
import { ReactComponent as TopRightBgImage } from "src/assets/illustrations/new_landing_bg_2.svg";
import { ReactComponent as NonprofitImage } from "src/assets/illustrations/nonprofit_color.svg";
import { LoadImageWithPriorityProvider } from "src/context/LoadImageWithPriorityContext/LoadImageWithPriorityContext";
import { EdoTheme } from "src/context/ThemeContext";
import { ButtonRole } from "src/styles/button";
import { colorCssVars, lightBgThemeNoBgCss } from "src/theme/color";
import {
  minWidthForMediaSize,
  MediaSize,
  cssForMediaSize,
} from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { textSizeCss } from "src/theme/text";
import { useABTestTrack, useStatSigLayer } from "src/utility/abtesting";
import { getWindow } from "src/utility/window";

//#region Styles
const StyledHeroPageSection = styled.section`
  display: flex;
  flex-direction: column;
  ${lightBgThemeNoBgCss};
  background: linear-gradient(180deg, #ffffff 0%, #e6f3f0 100%);
  position: relative;
  height: calc(100vh - 150px);
  height: calc(100svh - 150px);
  overflow: hidden;
  min-height: 480px;

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      height: calc(100vh - 106px);
      min-height: 650px;
    `,
  })}

  ${cssForMediaSize({
    min: MediaSize.XX_LARGE,
    css: css`
      min-height: 400px;
    `,
  })}
`;

const HeroContent = styled.div`
  overflow: hidden;
  flex-basis: calc(
    100% - 56px
  ); // 56px is the height of the see recent donations button
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: ${spacing.l};

  width: 100%;
  max-width: var(${SECTION_WIDTH_VAR});
  margin-left: auto;
  margin-right: auto;

  padding-right: ${spacing.xs};
  padding-left: ${spacing.xs};

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      gap: ${spacing.xxxl};
    `,
  })}

  ${cssForMediaSize({
    min: MediaSize.XX_LARGE,
    css: css`
      padding-right: ${spacing.xl};
      padding-left: ${spacing.xl};
      flex-direction: row;
    `,
  })}
`;

const HeroText = styled.div`
  z-index: 2;
  max-width: 575px;
  text-align: center;
  flex-grow: 0;
  ${verticalStackCss.s};
  padding-top: ${spacing.l};

  h1 {
    ${textSizeCss.xl}
  }

  p {
    ${textSizeCss.m}
  }

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      ${verticalStackCss.m};
      padding-top: ${spacing.xxxl};
      h1 {
        line-height: ${64 / 16}rem;
        font-size: ${68 / 16}rem;
      }
    `,
  })}

  ${cssForMediaSize({
    min: MediaSize.XX_LARGE,
    css: css`
      flex: 1 1 50%;
      padding-top: 0;
      text-align: left;
    `,
  })}
`;

const HeroButtonsContainer = styled.div`
  position: relative;
  ${verticalStackCss.xs};

  .heroButton {
    z-index: 2;
  }

  .heroButton-bg_bottomLeft,
  .heroButton-bg_topRight {
    position: absolute;
    z-index: 1;
  }

  .heroButton-bg_bottomLeft {
    left: -56px;
    bottom: -34px;
  }

  .heroButton-bg_topRight {
    right: -48px;
    top: -24px;
  }

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      flex-shrink: 0;
      margin: 0 auto;
    `,
  })}
`;

const StyledHeroButton = styled(Button)`
  display: flex;
  padding: calc(${spacing.m} - 2px);
  border: 2px solid var(${colorCssVars.accent.large});
  border-radius: 16px;
  max-width: 440px;
  background-color: var(${colorCssVars.background.normal});
  transition: border-color 0.1s ease-in, box-shadow 0.1s ease-in;

  &:hover {
    border: 2px solid var(${colorCssVars.accent.largeHighlight});
    box-shadow: 0px 8px 32px -16px rgba(46, 52, 52, 0.2);

    .heroButton-arrow {
      transform: translateX(8px);
    }
  }

  .heroButton-content {
    ${horizontalStackCss.m};
    align-items: center;
  }

  .heroButton-text {
    ${verticalStackCss.xs};
    text-align: start;
  }

  .heroButton-title {
    ${horizontalStackCss.s};
    align-items: center;

    & > h3 {
      ${textSizeCss.m};
    }
  }

  .heroButton-description {
    ${textSizeCss.xs};
    color: var(${colorCssVars.text.secondary});
  }

  .heroButton-arrow {
    flex-shrink: 0;
    transition: transform 0.1s ease-in;
  }

  .heroButton-avatar_small > svg {
    width: 32px;
    height: 32px;
  }

  .heroButton-avatar_large > svg {
    width: 88px;
    height: 88px;
  }

  .heroButton-avatar_small {
    display: flex;
  }
  .heroButton-avatar_large {
    display: none;
  }

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      border-radius: 500px;
      padding-right: ${spacing.xl};

      .heroButton-text {
        ${verticalStackCss.xxs};
      }
      .heroButton-description {
        ${textSizeCss.s}
      }

      .heroButton-avatar_large {
        display: flex;
      }
      .heroButton-avatar_small {
        display: none;
      }
    `,
  })}
`;

const pivotalBannerPageSectionCss = css`
  min-height: 660px;

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      min-height: 830px;
    `,
  })}

  ${cssForMediaSize({
    min: MediaSize.XX_LARGE,
    css: css`
      min-height: 550px;
    `,
  })}
`;

const recentDonationsButtonContentCss = css`
  ${horizontalStackCss.xs};
  justify-content: center;
  align-items: center;
`;
//#endregion

//#region HeroButton
interface HeroButtonProps {
  title: string;
  description: string;
  avatar: React.ReactNode;
  to: string;
  "data-tname": string;
}

const HeroButton = ({
  title,
  description,
  avatar,
  to,
  "data-tname": dataTname,
}: HeroButtonProps) => {
  return (
    <StyledHeroButton
      className="heroButton"
      contentClassName="heroButton-content"
      role={ButtonRole.UNSTYLED}
      onClick={{
        kind: ButtonTargetKind.LINK,
        to,
      }}
      data-tname={dataTname}
    >
      <div className="heroButton-avatar_large">{avatar}</div>
      <div className="heroButton-text">
        <span className="heroButton-title">
          <div className="heroButton-avatar_small">{avatar}</div>
          <h3>{title}</h3>
        </span>
        <p className="heroButton-description">{description}</p>
      </div>
      <Icon
        className="heroButton-arrow"
        iconImport={() => import("@components/Icon/icons/ArrowForwardIcon")}
        size={IconSize.MEDIUM}
        display={IconDisplay.ACCENT}
      />
    </StyledHeroButton>
  );
};
//#endregion

//#region LandingPage
interface LandingPageProps {
  feedItems?: FeedItemResponse[];
}

export const LandingPage: NextPage<LandingPageProps> = ({ feedItems }) => {
  // A/A test, don't actually do anything but split users into different variants
  // Metrics should not differ between the two
  useABTestTrack(LoggedOutAATest);

  const priorityItems =
    feedItems
      ?.slice(0, 4)
      .map((item) =>
        item.type === FeedItemType.USER_DONATION
          ? (item.donationCharge.toNonprofitId as string)
          : ""
      ) || [];

  const scrollToRecentDonations = () => {
    const window = getWindow();
    if (window) {
      window.scrollTo({
        top:
          window.innerHeight -
          (window.innerWidth < minWidthForMediaSize[MediaSize.MEDIUM] ? 0 : 75),
        behavior: "smooth",
      });
    }
  };

  const showPivotalBanner = useStatSigLayer("banner").get<boolean>(
    "show",
    false
  );

  return (
    <LoadImageWithPriorityProvider initialValue={{ ids: priorityItems }}>
      <DefaultPageLayout
        themeOverride={EdoTheme.LIGHT}
        css={defaultPageLayoutCss}
        footerCss={defaultPageLayoutFooterCss}
        pageTitle={
          "Every.org: Online Fundraising Platform Built for Nonprofits and Donors"
        }
        metas={{
          "og:description":
            "Every.org is a tech-for-good nonprofit that simplifies online giving for nonprofits and donors.",
        }}
        // This page (/index-public) is served at / via rewrite
        canonical={
          assertEnvPresent(
            process.env.NEXT_PUBLIC_WEBSITE_ORIGIN,
            "WEBSITE_ORIGIN"
          ) + "/"
        }
      >
        <StyledHeroPageSection
          css={showPivotalBanner && pivotalBannerPageSectionCss}
        >
          {showPivotalBanner && <PivotalBanner />}
          <HeroContent>
            <HeroText>
              <h1>
                Easier giving.
                <br /> Greater good.
              </h1>
              <p>
                Every.org is a tech-for-good nonprofit that simplifies
                online&nbsp;giving for nonprofits and donors.
              </p>
            </HeroText>
            <HeroButtonsContainer>
              <BottomLeftBgImage className="heroButton-bg_bottomLeft" />
              <TopRightBgImage className="heroButton-bg_topRight" />
              <HeroButton
                data-tname="for-nonprofits"
                title="For nonprofits"
                description="Grow generosity with our donate button and fundraisers."
                avatar={<NonprofitImage />}
                to={getRoutePath({
                  name: ClientRouteName.NONPROFITS_LANDING,
                  format: URLFormat.RELATIVE,
                })}
              />
              <HeroButton
                data-tname="for-donors"
                title="For donors"
                description="Give cash, crypto, and stocks on a&nbsp;platform built by a nonprofit."
                avatar={<UserImage />}
                to={getRoutePath({
                  name: ClientRouteName.SIGNUP,
                  format: URLFormat.RELATIVE,
                })}
              />
            </HeroButtonsContainer>
          </HeroContent>
          <LandingSeeRecentDonationsButtonsSection>
            <LandingSeeRecentDonationsButton
              data-tname="LandingPage-ShowRecentDonations-Button"
              role={ButtonRole.PRIMARY}
              contentCss={recentDonationsButtonContentCss}
              onClick={{
                kind: ButtonTargetKind.FUNCTION,
                action: scrollToRecentDonations,
              }}
            >
              <span>See recent donations</span>
              <Icon
                iconImport={() =>
                  import("@components/Icon/icons/SelectArrowIcon")
                }
                size={IconSize.SMALL}
                display={IconDisplay.CURRENT_COLOR}
              />
            </LandingSeeRecentDonationsButton>
          </LandingSeeRecentDonationsButtonsSection>
        </StyledHeroPageSection>
        <PageSection contentCss={landingFeedPageSectionCss}>
          <LandingFeed initialItems={feedItems} />
        </PageSection>
      </DefaultPageLayout>
    </LoadImageWithPriorityProvider>
  );
};
//#endregion
